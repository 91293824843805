import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { GridLayout } from '../mui';
export const SelectGrid = ({ errorMessage, hideLabel, inputRef, label, onChange, options, placeholder, required, type = 'number', xs = 12, name, id = `select-${name}`, value, ...rest }) => {
    const labelId = `${id}-label`;
    return (_jsx(GridLayout, { xs: xs, label: label, hideLabel: hideLabel, required: required, children: _jsxs(FormControl, { sx: { minWidth: 70 }, fullWidth: true, children: [placeholder && (_jsx(InputLabel, { id: labelId, variant: "outlined", size: "small", children: placeholder })), _jsx(Select, { type: type, labelId: labelId, id: id, name: name, label: placeholder, value: value ?? '', variant: "outlined", inputRef: inputRef, required: required, size: "small", onChange: onChange, ...rest, error: !!errorMessage, MenuProps: {
                        sx: {
                            maxHeight: '300px',
                        },
                    }, children: options?.map((option) => {
                        return (_jsx(MenuItem, { value: option.value, disabled: option.disabled, children: option.label }, option.value));
                    }) }), _jsx(FormHelperText, { error: !!errorMessage, children: errorMessage })] }) }));
};
