import { jsx as _jsx } from "react/jsx-runtime";
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { TextFieldGrid } from '../TextFieldGrid';
export const AtomTextField = (props) => {
    const { atom, type, ...inputProps } = props;
    const [value, setValue] = useAtom(atom);
    return (_jsx(TextFieldGrid, { ...inputProps, type: type, value: value, onChange: (e) => {
            const { type, value } = e.target;
            if (!value) {
                setValue(RESET);
                return;
            }
            if (type === 'number') {
                const numValue = Number(value);
                if (Number.isNaN(numValue))
                    return;
                setValue(numValue);
            }
            else {
                setValue(value);
            }
        } }));
};
