import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useDisclosure } from 'shared/hooks';
import { sleep } from '@atnd/utils';
import { Flex } from '../mui/Box';
import { AppLoadingButton, ContainedButton } from '../mui/Button';
const StyledDialog = styled(Dialog)(() => ({
    '& .MuiDialog-paper': {
        borderRadius: '16px',
        boxShadow: '0 10px 40px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(145deg, #ffffff, #f8f9fa)',
        border: 'none',
        overflow: 'hidden',
    },
}));
const DialogHeader = styled('div')(() => ({
    padding: '24px 32px',
    borderBottom: '1px solid #e0e0e0',
}));
const StyledDialogContent = styled(DialogContent)(() => ({
    padding: '32px',
}));
const DialogFooter = styled('div')(() => ({
    padding: '24px 32px',
    borderTop: '1px solid #e0e0e0',
}));
const StyledButton = styled(AppLoadingButton)(() => ({
    borderRadius: '8px',
    padding: '10px 24px',
    textTransform: 'none',
    fontWeight: 600,
    boxShadow: 'none',
    transition: 'all 0.3s ease',
    '&:hover': {
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        transform: 'translateY(-2px)',
    },
}));
export const AppDialog = ({ buttonProps = { sx: {} }, cancelLabel, children, maxWidth = 'sm', onCancel, onSave, disabledSave = false, saveLabel, title, triggerElement, loadingUntilRedirect, saveButton, disabled, saveButtonProps, }) => {
    const { getButtonProps, getDisclosureProps, onClose } = useDisclosure();
    const handleSave = async () => {
        if (loadingUntilRedirect) {
            onSave?.();
            return sleep(10000);
        }
        return onSave?.(onClose);
    };
    const handleCancel = async () => {
        onCancel?.();
        onClose();
    };
    const { sx: buttonSx, ...restButtonProps } = buttonProps;
    return (_jsxs(_Fragment, { children: [_jsx(ContainedButton, { ...getButtonProps(), ...restButtonProps, sx: { ...buttonSx, borderRadius: 2 }, disabled: disabled, children: triggerElement }), _jsxs(StyledDialog, { ...getDisclosureProps(), "aria-labelledby": "custom-dialog-title", maxWidth: maxWidth, fullWidth: true, children: [_jsx(DialogHeader, { children: _jsx(Typography, { variant: "h6", fontWeight: "bold", children: title }) }), _jsx(StyledDialogContent, { children: children }), _jsx(DialogFooter, { children: _jsxs(Flex, { justifyContent: "flex-end", gap: 2, children: [onCancel && (_jsx(StyledButton, { color: "secondary", onClick: handleCancel, variant: "outlined", children: cancelLabel })), saveButton
                                    ? saveButton
                                    : onSave && (_jsx(StyledButton, { ...saveButtonProps, onClick: handleSave, autoFocus: true, color: "primary", disabled: disabledSave, children: saveLabel }))] }) })] })] }));
};
