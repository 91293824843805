import { jsx as _jsx } from "react/jsx-runtime";
import { appDayjs, formatDate } from '@atnd/dayjs';
import { GridLayout } from '../mui/layouts/GridLayout';
import { DatePicker } from './DatePicker';
export const DatePickerGrid = ({ annotation, hideLabel, label, onChange, required, xs = 6, ...rest }) => {
    return (_jsx(GridLayout, { label, annotation, xs, hideLabel, required, children: _jsx(DatePicker, { ...rest, onChange: (e) => {
                if (e instanceof appDayjs) {
                    onChange?.(formatDate(e));
                }
                else {
                    onChange?.(e);
                }
            } }) }));
};
