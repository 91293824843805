import { jsx as _jsx } from "react/jsx-runtime";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { InputAdornment } from '@mui/material';
import { IconButton, TextField } from '@mui/material';
import { useState } from 'react';
export const PasswordTextField = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };
    return (_jsx(TextField, { ...props, InputProps: {
            endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowPassword, edge: "end", children: showPassword ? _jsx(Visibility, {}) : _jsx(VisibilityOff, {}) }) })),
        }, type: showPassword ? 'text' : 'password' }));
};
