import { isArray } from 'remeda';
const getFieldError = (name, value) => {
    const names = name.split('.');
    if (!value)
        return;
    switch (names.length) {
        case 1:
            // @ts-ignore
            return value[name];
        case 2:
            // @ts-ignore
            return value[names[0]]?.[names[1]];
        case 3:
            // @ts-ignore
            return value[names[0]]?.[names[1]]?.[names[2]];
        default:
            return undefined;
    }
};
const isFieldError = (fieldError) => {
    if (fieldError === undefined) {
        return false;
    }
    if (isArray(fieldError)) {
        return false;
    }
    return true;
};
const isFieldErrors = (fieldError) => {
    if (fieldError === undefined) {
        return false;
    }
    if (isArray(fieldError)) {
        return true;
    }
    return false;
};
const getRhfErrorMessage = (name, value) => {
    if (!value)
        return;
    const fieldError = getFieldError(name, value);
    if (isFieldError(fieldError)) {
        return fieldError.message;
    }
    return;
};
const getFieldErrors = (name, value) => {
    if (!value)
        return [];
    const fieldError = getFieldError(name, value);
    if (isFieldErrors(fieldError)) {
        return fieldError.map((error) => {
            return error;
        });
    }
    return [];
};
export { getFieldError, getFieldErrors, getRhfErrorMessage };
