import { jsx as _jsx } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { DatePickerGrid } from '../inputs';
import { getRhfErrorMessage } from './utils';
/**
 * react-hook-formラッパー
 */
export const RhfDatePickerGrid = (props) => {
    const { control, defaultValue: _, name, rules: __, shouldUnregister: ___, ...restProps } = props;
    const { field: { ref, ...fieldProps }, formState: { errors }, } = useController({ name, control });
    return _jsx(DatePickerGrid, { inputRef: ref, error: getRhfErrorMessage(name, errors), ...restProps, ...fieldProps });
};
