import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TableCell, Typography } from '@mui/material';
import { FlexCol } from '../../mui/Box';
export const WeekdayCellLayout = ({ color, dateElement, weekdayElement }) => {
    return (_jsx(TableCell, { sx: tableHeadCellStyles, children: _jsxs(FlexCol, { color: color, children: [_jsx(Typography, { fontSize: { xs: 10, sm: 12 }, children: weekdayElement }), _jsx(Typography, { fontSize: { xs: 12, sm: 14 }, children: dateElement })] }) }));
};
const tableHeadCellStyles = {
    textAlign: 'center',
    fontWeight: 'bold',
    p: 0,
};
