import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useController } from 'react-hook-form';
import { SelectGrid } from '../inputs';
import { getErrorMessage } from 'shared/rhf';
/**
 * react-hook-formラッパー
 */
export const RhfSelectGrid = (props) => {
    const { 
    // unused
    control, defaultValue, error, name, 
    // used
    onChange, placeholder, rules, shouldUnregister, ...restProps } = props;
    const { field: { onChange: rhfChange, ref, ...rest }, formState: { errors }, } = useController({ name, control });
    const handleChange = (event, child) => {
        onChange?.(event, child);
        rhfChange(event);
    };
    const errorMessage = getErrorMessage(name, errors);
    return (_jsx(Controller, { name: name, control: control, render: ({ field }) => {
            return (_jsx(SelectGrid, { inputRef: ref, placeholder: placeholder, ...rest, ...field, onChange: handleChange, ...restProps, errorMessage: errorMessage }));
        } }));
};
