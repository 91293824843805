import { jsx as _jsx } from "react/jsx-runtime";
import { appDayjs } from '@atnd/dayjs';
import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { isString } from 'remeda';
export const DatePicker = ({ dataCy, disabled, displayTime, error, initialDate, inputRef, name, onChange, openTo = 'day', placeholder, readOnly = false, value, ...rest }) => {
    return (_jsx(DesktopDatePicker, { ...rest, ref: inputRef, onChange: (date) => {
            const newValue = date ?? '';
            if (isString(newValue)) {
                onChange?.(newValue);
            }
            else {
                const newValue = date;
                onChange?.(newValue.format(displayTime ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD'));
            }
        }, inputFormat: displayTime ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD', mask: displayTime ? '____/__/__ __:__:__' : '____/__/__', value: value || null, readOnly: readOnly, openTo: openTo, onOpen: () => {
            if (value || !initialDate)
                return;
            onChange?.(appDayjs(initialDate));
        }, dayOfWeekFormatter: (dayOfWeek) => {
            if (dayOfWeek === 'Su') {
                return '日';
            }
            if (dayOfWeek === 'Mo') {
                return '月';
            }
            if (dayOfWeek === 'Tu') {
                return '火';
            }
            if (dayOfWeek === 'We') {
                return '水';
            }
            if (dayOfWeek === 'Th') {
                return '木';
            }
            if (dayOfWeek === 'Fr') {
                return '金';
            }
            if (dayOfWeek === 'Sa') {
                return '土';
            }
            return '';
        }, InputProps: {
            sx: {
                backgroundColor: 'white',
            },
        }, disabled: disabled, renderInput: (params) => (_jsx(TextField, { ...params, name: name, variant: "outlined", size: "small", error: !!error, helperText: error, inputProps: {
                ...params.inputProps,
                placeholder,
            }, disabled: disabled, "data-cy": dataCy })) }));
};
