import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { appDayjs } from '@atnd/dayjs';
import { Box, Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { RESERVATION_STATUS_COLORS } from 'shared/constants';
import { AppDateCalendar, Flex } from '../mui';
export const ReservationCalendarHead = ({ actions, clinicAlias, date, handleChangeMode, mode, to, toCurrentWeek, toNextWeek, toPrevWeek, }) => {
    /* 麻布台クリニックの場合は当日の来院を受け付ける
     * @see https://discord.com/channels/1056898625726316624/1056898625726316627/1309829910210150441
     **/
    const openToday = clinicAlias.includes('AZC');
    const AVAILABILITY_MARKS = [
        { mark: '◎', color: RESERVATION_STATUS_COLORS.AVAILABLE, text: '空きあり' },
        { mark: '○', color: RESERVATION_STATUS_COLORS.AVAILABLE, text: 'やや混雑' },
        { mark: '△', color: RESERVATION_STATUS_COLORS.BUSY, text: '混雑' },
        {
            mark: '×',
            color: RESERVATION_STATUS_COLORS.UNAVAILABLE,
            text: '空きなし',
        },
        {
            mark: '☆',
            color: RESERVATION_STATUS_COLORS.WALK_IN,
            text: '来院順受付（予約優先）',
        },
    ];
    const filteredAvailabilityMarks = openToday
        ? AVAILABILITY_MARKS
        : AVAILABILITY_MARKS.filter((mark) => mark.mark !== '☆');
    return (_jsxs(_Fragment, { children: [_jsxs(Flex, { justifyContent: { xs: 'space-around', sm: 'space-between' }, alignItems: "center", gap: { xs: 1, sm: 0 }, px: 2, width: "100%", children: [_jsx(Box, { width: { xs: '100px', sm: '140px' }, children: _jsx(Typography, { fontSize: { xs: 18, sm: 24 }, children: to ? (_jsx(AppDateCalendar, { value: date, InputProps: {
                                    sx: { fontSize: '24px', width: '180px' },
                                }, onChange: (date) => {
                                    if (!date)
                                        return;
                                    const newDate = date.format('YYYY-MM-DD');
                                    if (appDayjs(newDate).isValid()) {
                                        to(newDate);
                                    }
                                } })) : (date) }) }), _jsxs(Flex, { ml: "-10%", gap: 10, children: [_jsxs(Flex, { children: [_jsx(Button, { sx: { ...buttonStyle, borderRadius: '10px 0 0 10px' }, onClick: toPrevWeek, children: _jsx(Typography, { fontSize: buttonFontSize, children: `< 前週` }) }), _jsx(Button, { sx: { ...buttonStyle, borderLeft: 'none', borderRight: 'none' }, onClick: toCurrentWeek, children: _jsx(Typography, { fontSize: buttonFontSize, children: "\u4ECA\u9031" }) }), _jsx(Button, { sx: { ...buttonStyle, borderRadius: '0 10px 10px 0' }, onClick: toNextWeek, children: _jsx(Typography, { fontSize: buttonFontSize, children: `次週 >` }) })] }), mode && (_jsx(Flex, { children: _jsxs(ToggleButtonGroup, { value: mode, size: "small", onChange: handleChangeMode, exclusive: true, "aria-label": "calendar display mode", children: [_jsx(ToggleButton, { value: "day", children: "\u9031\u9593\u8868\u793A" }), _jsx(ToggleButton, { value: "week", children: "\u66DC\u65E5\u56FA\u5B9A" })] }) }))] }), _jsx(Flex, { display: { xs: 'none', sm: 'flex' }, children: actions })] }), _jsx(Flex, { gap: 1, flexWrap: "wrap", children: filteredAvailabilityMarks.map(({ color, mark, text }) => (_jsxs(Flex, { gap: 1, "aria-label": `${text}を表す記号は${mark}です`, whiteSpace: "nowrap", children: [_jsx(Typography, { component: "span", fontWeight: "bold", color: color, fontSize: 12, children: mark }), _jsx(Typography, { component: "span", fontSize: 12, children: text })] }, mark))) })] }));
};
const buttonFontSize = { xs: '11px', sm: '14px' };
const buttonStyle = {
    height: { xs: '24px', sm: '36px' },
    minWidth: { xs: '40px', sm: '64px' },
    border: '1px solid #333',
    variant: 'text',
    whiteSpace: 'nowrap',
    color: '#333',
};
