import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputAdornment } from '@mui/material';
import { MenuItem, TextField } from '@mui/material';
import { GridLayout } from '../mui/layouts/GridLayout';
import { PasswordTextField } from './PasswordTextField';
export const TextFieldGrid = ({ adornment, annotation, dataCy, hideLabel, inputRef, label, name, options, required, type = 'text', validationError, value, xs, ...inputProps }) => {
    const commonProps = {
        name,
        required,
        type,
        value: value ?? '',
        xs,
        size: 'small',
        variant: 'outlined',
        fullWidth: true,
        error: !!validationError,
        helperText: validationError,
        'data-cy': dataCy,
        ...inputProps,
    };
    return (_jsx(GridLayout, { label, annotation, xs, hideLabel, required, children: type === 'password' ? (_jsx(PasswordTextField, { inputRef: inputRef, ...commonProps })) : (_jsxs(TextField, { inputRef: inputRef, SelectProps: { style: { fontSize: '14px' } }, InputProps: {
                style: {
                    borderRadius: '6px',
                },
                endAdornment: adornment && _jsx(InputAdornment, { position: "end", children: adornment }),
            }, ...commonProps, ...inputProps, children: [_jsx(MenuItem, { sx: { py: 2 }, children: '' }), options?.map((option, index) => {
                    return (_jsx(MenuItem, { value: option.value, disabled: option.disabled, children: option.label }, `${name}-${option.label}-${index}`));
                })] })) }));
};
