import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Skeleton } from '@mui/material';
import { GridContainer } from '../../layouts/GridContainer';
import { FlexCol } from '../mui';
export const ReservationCalendarSkeleton = () => {
    return (_jsx(FlexCol, { pl: 0, pb: 2, mt: -1, width: "100%", children: [...Array(18)].map((_, pi) => {
            const time = `${Math.floor(pi / 2 + 9)}:${pi % 2 === 0 ? '00' : '30'}`;
            return (_jsxs(GridContainer, { columns: 8.2, spacing: "2px", children: [_jsx(Grid, { item: true, xs: 1.2, px: "2px", height: cellHeight * 0.7, children: _jsx(Skeleton, { width: "100%", height: cellHeight }) }), [...Array(7)].map((_, di) => {
                        return (_jsx(Grid, { item: true, xs: 1, px: "2px", children: _jsx(Skeleton, { width: "100%", height: cellHeight }) }, `skeleton-cell-${time}-${di + 1}`));
                    })] }, `skeleton-row-${time}`));
        }) }));
};
const cellHeight = 48;
