import { isArray } from 'remeda';
/**
 * オブジェクトのフィールドからエラーメッセージを取得する
 * @param values オブジェクト
 * @param fieldName フィールド名（"."で区切った場合、ネストしたフィールドを参照できます）
 * @returns エラーメッセージ、エラーオブジェクトの配列、またはundefined
 */
const getFieldError = (values, fieldName) => {
    const fieldNames = fieldName.split('.');
    const getErrorTarget = () => {
        switch (fieldNames.length) {
            case 1:
                return values[fieldNames[0]];
            case 2:
                //@ts-ignore
                return values[fieldNames[0]]?.[fieldNames[1]];
            case 3:
                //@ts-ignore
                return values[fieldNames[0]]?.[fieldNames[1]]?.[fieldNames[2]];
            default:
                return undefined;
        }
    };
    return getErrorTarget();
};
export const isFieldError = (fieldError) => {
    if (fieldError === undefined) {
        return false;
    }
    if (isArray(fieldError)) {
        return false;
    }
    return true;
};
export const isFieldErrors = (fieldError) => {
    if (fieldError === undefined) {
        return false;
    }
    if (isArray(fieldError)) {
        return true;
    }
    return false;
};
export const getErrorMessage = (name, value) => {
    if (!value) {
        return undefined;
    }
    const fieldError = getFieldError(value, name);
    if (isFieldError(fieldError)) {
        return fieldError.message;
    }
    return undefined;
};
