'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { TimePicker } from '../inputs';
/**
 * react-hook-formラッパー
 */
export const RhfTimePicker = ({ control, disabled, error, helperText, name, ...restProps }) => {
    const { field: { onChange, ref, value }, fieldState: { error: fieldError }, } = useController({ name, control });
    return (_jsx(TimePicker, { inputRef: ref, value: value, onChange: onChange, disabled: disabled, error: error || !!fieldError, helperText: helperText || fieldError?.message, ...restProps }));
};
