import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormHelperText } from '@mui/material';
import { useController } from 'react-hook-form';
import { RadioGrid } from '../inputs';
import { getRhfErrorMessage } from './utils';
import { parseBoolean } from '@atnd/utils';
import { isBoolean, isNumber, isString } from 'remeda';
/**
 * react-hook-formラッパー
 */
export const RhfRadioGrid = ({ control, disabledToBoolean, name, rules: _, shouldUnregister: __, ...restProps }) => {
    const { field: { onChange, ref, ...rest }, formState: { errors }, } = useController({ name, control });
    const rhfOnChange = (_, value) => {
        const numberValue = Number(value);
        if (isBoolean(value)) {
            onChange({
                target: {
                    value,
                    name,
                },
            });
        }
        else if (isNumber(numberValue)) {
            onChange({
                target: {
                    value: numberValue,
                    name,
                },
            });
        }
        else if (isString(value)) {
            const newValue = disabledToBoolean ? value : parseBoolean(value);
            onChange({
                target: {
                    value: newValue,
                    name,
                },
            });
        }
    };
    const errorMessage = getRhfErrorMessage(name, errors);
    return (_jsxs(Box, { children: [_jsx(RadioGrid, { ...rest, ...restProps, inputRef: ref, onChange: rhfOnChange }), errorMessage && _jsx(FormHelperText, { error: true, children: errorMessage })] }));
};
