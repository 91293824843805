import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { LINK_COLOR, LINK_HOVER_COLOR } from './constants/color';
export const ExternalLink = ({ children, ...props }) => {
    return (_jsx("a", { ...props, style: {
            textDecoration: 'none',
            color: LINK_COLOR,
            transition: 'color 0.3s ease',
        }, children: _jsx(Typography, { component: "span", sx: {
                '&:hover': {
                    color: LINK_HOVER_COLOR,
                },
            }, children: children }) }));
};
