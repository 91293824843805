import { jsx as _jsx } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { TextFieldGrid } from '../inputs';
import { getRhfErrorMessage } from '.';
/**
 * react-hook-formラッパー
 */
export const RhfTextFieldGrid = (props) => {
    const { control, defaultValue: __, error: ___, name, onBlur, placeholder, rules: _____, shouldUnregister: ______, type, value: _, ...restProps } = props;
    const { field: { onBlur: rhfOnBlur, onChange: rhfOnChange, ref, ...rest }, formState: { errors }, } = useController({ name, control });
    const handleBluer = (e) => {
        onBlur?.(e);
        rhfOnBlur();
    };
    const handleChange = (e) => {
        if (type === 'number') {
            const value = e.target.value;
            if (value === '') {
                rhfOnChange({ ...e, target: { ...e.target, value: null } });
            }
            else {
                const numValue = Number(value);
                rhfOnChange({ ...e, target: { ...e.target, value: numValue } });
            }
        }
        else {
            rhfOnChange(e);
        }
    };
    return (_jsx(TextFieldGrid, { inputRef: ref, type: type, placeholder: placeholder, validationError: getRhfErrorMessage(name, errors), onBlur: handleBluer, onChange: handleChange, ...rest, ...restProps }));
};
