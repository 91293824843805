import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AppLoadingButton, ContainedButton, Flex } from '../mui';
import { Button } from '@mui/material';
import { useDisclosure } from 'shared/hooks';
export const AlertDialogButton = ({ agreeLabel, buttonProps, children, confirmDataCy, content, description, disagreeLabel, onAgree, onDisagree, submitButtonColor = 'error', title, triggerDataCy, }) => {
    const { getButtonProps, getDisclosureProps, onClose } = useDisclosure();
    const handleAgree = async () => {
        return onAgree().then(() => {
            onClose();
        });
    };
    const handleDisagree = () => {
        onDisagree?.();
        onClose();
    };
    const disclosureButtonProps = getButtonProps();
    return (_jsxs(_Fragment, { children: [_jsx(ContainedButton, { ...disclosureButtonProps, ...buttonProps, sx: { borderRadius: 2, ...buttonProps?.sx }, onClick: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    disclosureButtonProps.onClick?.(e);
                }, "data-cy": triggerDataCy, children: children }), _jsxs(Dialog, { ...getDisclosureProps(), "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", PaperProps: { sx: { borderRadius: 2 } }, maxWidth: "xs", fullWidth: true, children: [_jsx(DialogTitle, { id: "alert-dialog-title", whiteSpace: "pre-wrap", children: title }), _jsxs(DialogContent, { onClick: (e) => e.stopPropagation(), children: [_jsx(DialogContentText, { id: "alert-dialog-description", whiteSpace: "pre-wrap", color: "#333", children: description }), content] }), _jsx(DialogActions, { children: _jsxs(Flex, { gap: 2, px: 1, children: [_jsx(Button, { color: "secondary", onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleDisagree();
                                    }, children: disagreeLabel }), _jsx(AppLoadingButton, { variant: "contained", onClick: async (e) => {
                                        e?.preventDefault();
                                        e?.stopPropagation();
                                        await handleAgree();
                                    }, autoFocus: true, color: submitButtonColor, sx: { px: 2 }, dataCy: confirmDataCy, children: agreeLabel })] }) })] })] }));
};
