import { jsx as _jsx } from "react/jsx-runtime";
import { formatDate } from '@atnd/dayjs';
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { DatePicker } from '../DatePicker';
export const AtomDatePicker = (props) => {
    const { atom, displayTime, ...rest } = props;
    const [value, setValue] = useAtom(atom);
    return (_jsx(DatePicker, { ...rest, displayTime: displayTime, value: value, onChange: (date) => {
            if (!date) {
                setValue(RESET);
                return;
            }
            if (displayTime) {
                setValue(formatDate(date, 'YYYY-MM-DD HH:mm'));
                return;
            }
            setValue(formatDate(date));
        } }));
};
