import { jsx as _jsx } from "react/jsx-runtime";
import { InputAdornment, OutlinedInput } from '@mui/material';
import { useState } from 'react';
export const OutlinedNumberInput = ({ adornment, max, min, onBlur, ...props }) => {
    const [value, setValue] = useState(undefined);
    return (_jsx(OutlinedInput, { ...props, type: "number", size: "small", endAdornment: adornment && _jsx(InputAdornment, { position: "end", children: adornment }), onChange: ({ target }) => {
            if (!target.value)
                return setValue(undefined);
            const numValue = Number(target.value);
            if (min && numValue < min) {
                return setValue(min);
            }
            if (max && numValue > max) {
                return setValue(max);
            }
            setValue(numValue);
        }, onBlur: () => {
            if (value === undefined)
                return;
            onBlur?.(value);
        }, sx: { width: 120, bgcolor: 'white' }, inputProps: { min: 0, max: 15 } }));
};
